import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./listing.css";
import "../components/PostListing/PostListing.scss";

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
    const nextPage = `/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    const numOfPrevNextPages = 3; // count of pages +- around current page
    console.log(currentPageNum);

    var pages = [];

    // Adding previous pages
    for (var i = numOfPrevNextPages; i > 0; i--) {
      console.log("(prev) Running " + i + " of " + numOfPrevNextPages);

      const pageNum = currentPageNum - i;
      console.log("pageNum " + pageNum + ", which will be linked: " + (pageNum > 0));

      if (pageNum > 0) {
        pages.push(
          <Link
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? "/" : `/${pageNum}/`}
            >
              {pageNum}
          </Link>
        );
      }
    }

    // Adding next pages
    for (var i = 0; i < numOfPrevNextPages; i++) {
      console.log("(next) Running " + i + " of " + numOfPrevNextPages);

      const pageNum = currentPageNum + i;

      var maxShownPages = currentPageNum + numOfPrevNextPages;
      if (maxShownPages > pageCount) {
        maxShownPages = pageCount;
      }

      console.log("pageNum " + pageNum + ", which will be linked: " + (pageNum <= maxShownPages));

      if (pageNum <= maxShownPages) {
        pages.push(
          <Link
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? "/" : `/${pageNum}/`}
            >
              {pageNum}
          </Link>
        );
      }
    }

    console.log("number of pages that will be shown: " + pages.length);

    return (
      <div className="paging-container">
        {!isFirstPage && <Link to={prevPage}>Previous</Link>}
        {pages.map((page, i) => {
          return (page);
        })}
        {!isLastPage && <Link to={nextPage}>Next</Link>}
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMdx.edges;

    return (
      <Layout>
        <div className="listing-container">
          <div className="posts-container">
            <Helmet title={config.siteTitle} />
            <SEO />
            <PostListing postEdges={postEdges} />
          </div>
          {this.renderPaging()}
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          body
          fields {
            slug
            date
          }
          excerpt
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
